import React, { useState, useEffect, useCallback } from 'react'
import { DotButton, PrevButton, NextButton } from './emblaCarouselButtons'
import { useRecursiveTimeout } from './useRecursiveTimeout'
import { useEmblaCarousel } from 'embla-carousel/react'

const EmblaCarousel = ({ autoplay, arrows, children, dots, delayLength, loop, slidesToScroll }) => {

  const [viewportRef, embla] = useEmblaCarousel({ loop:loop, slidesToScroll:slidesToScroll || 1, align:'start' });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const auto = useCallback(() => {
    if (!embla || !autoplay) return;
    if (embla.canScrollNext()) {
      embla.scrollNext();
    } else {
      embla.scrollTo(0);
    }
  }, [autoplay, embla]);


  const { play, stop } = useRecursiveTimeout(auto, delayLength);

  const scrollNext = useCallback(() => {
    if (!embla) return;
    embla.scrollNext();
    stop();
  }, [embla, stop]);

  const scrollPrev = useCallback(() => {
    if (!embla) return;
    embla.scrollPrev();
    stop();
  }, [embla, stop]);

  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
    embla
  ]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
    embla.on('pointerDown', stop);
  }, [embla, onSelect, setScrollSnaps, stop]);

  useEffect(() => {
    play();
  }, [play]);

  return (
    <div className='embla relative w-full'>
      <div className='overflow-hidden' ref={viewportRef}>
        <div className='flex'>
          {children}
        </div>
      </div>
      {dots && (
        <div className='w-full text-center'>
          {scrollSnaps.map((snap, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
      )}
      {arrows && (
        <>
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </>
      )}
    </div>
  );
};

export default EmblaCarousel;
